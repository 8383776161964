<template>
  <div>
    <c-table
      ref="table"
      title="소방 훈련·교육 참석자 목록"
      :columns="grid.columns"
      :data="traningEdu.users"
      :gridHeight="grid.height"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="sopFireFightingTraningEduUserId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="삭제" icon="delete" @btnClicked="deleteTarget" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addTarget" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTraningEduPlanUser',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    traningEdu: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: '',  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenaios: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 150
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px'
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingTraningEdu.user.list.url
      this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.user.save.url
      this.deleteUrl = transactionConfig.fft.fireFightingTraningEdu.user.delete.url
      // code setting
      let height = this.contentHeight - 150
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px'
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId
      };
      this.$http.request((_result) => {
        this.$set(this.traningEdu, 'users', _result.data)
      },);
    },
    deleteTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.traningEdu.users = this.$_.reject(this.traningEdu.users, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTarget() {
      this.popupOptions.title = '사용자 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.traningEdu.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.traningEdu.users, { userId: item.userId }) === -1) {
            saveData.push({ 
              sopFireFightingTraningEduUserId: uid(),  // 소방 훈련·교육 계획/결과 참석자 일련번호
              sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId,  // 소방 훈련·교육 계획/결과 일련번호
              deptName: item.deptName,  // 참석자 부서
              userId: item.userId,  // 참석자 id
              userName: item.userName,  // 참석자 이름
              jobName: item.jobName,  // 참석자 직책
              attendanceFlag: 'N',  // 참석여부
              attendanceDt: '',  // 참석일시
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })

        this.$http.url = this.saveUrl;
        this.$http.type = 'PUT';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>

<style lang="sass">
.fire-fighting-traning-edu-year-gubun-tr
  td
    text-align: center
    color: white !important
    font-size: 1rem !important
    font-weight: 600
  .text-align-right
    text-align: right
</style>